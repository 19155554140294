import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateViewerMutation($input: UpdateViewerInput!) {
		updateViewer(input: $input) {
			errors
			viewer {
				email
				jobTitle
				department {
					id
					name
				}
				firstName
				lastName
				phone
				initials
				cost
				profiles(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
				role {
					id
					name
				}
				holidayCalendar {
					id
				}
				language
				invited
				profilePictureId
				personLabels(first: 10000) {
					edges {
						node {
							label {
								id
							}
						}
					}
				}
				unseenNotifications
				notificationFrequency
				notifyOnPersonJoin
				notifyOnProjectStatusChange
				notifyOnAssignedProject
				notifyOnProjectDeadline
				notifyOnAssignedTask
				notifyOnAssignedTaskUpdate
				notifyOnTaskDeadline
				notifyOnMention
				notifyOnInvoiceDueDate
				notifyOnInvoiceOverdue
				notifyOnInvoiceDaysOverdue
				notifyOnInvoiceCreatedOrDeleted
				notifyOnInvoiceStatusChange
				notifyOnInvoiceDateReached
				notifyOnInvoicePayment
				notifyOnInvoiceDateChanged
				notifyOnInvoiceDueDateChanged
				notifyOnTaskDescriptionChange
				notifyOnTaskTitleChange
				notifyOnTaskEstimateChange
				notifyOnTaskStartDateChange
				notifyOnTaskEndDateChange
				notifyOnTaskSprintChange
				notifyOnTaskPhaseChange
				notifyOnTaskStatusColumnChange
				notifyOnTaskProjectChange
				notifyOnTaskSubtaskChange
				notifyOnTaskSubtaskEstimateChange
				notifyOnTaskCommentChange
				notifyOnTaskFileChange
				notifyOnTaskBugChange
				notifyOnTaskBlockedChange
				notifyOnTaskRepeatingChange
				notifyOnTimeOffManager
				notifyOnTimeOffOwner
				disableNotifications
				projectDeadlineNotificationPeriod
				taskDeadlineNotificationPeriod
				invoiceNotificationDaysOverdue
				notifyInAppOnProjectStatusChange
				notifyInAppOnPersonJoin
				notifyInAppOnAssignedProject
				notifyInAppOnProjectDeadline
				notifyInAppOnAssignedTask
				notifyInAppOnAssignedTaskUpdate
				notifyInAppOnTaskDeadline
				notifyInAppOnMention
				notifyInAppOnInvoiceDueDate
				notifyInAppOnInvoiceOverdue
				notifyInAppOnInvoiceDaysOverdue
				notifyInAppOnInvoiceCreatedOrDeleted
				notifyInAppOnInvoiceStatusChange
				notifyInAppOnInvoiceDateReached
				notifyInAppOnInvoicePayment
				notifyInAppOnInvoiceDateChanged
				notifyInAppOnInvoiceDueDateChanged
				notifyInAppOnTaskDescriptionChange
				notifyInAppOnTaskTitleChange
				notifyInAppOnTaskEstimateChange
				notifyInAppOnTaskStartDateChange
				notifyInAppOnTaskEndDateChange
				notifyInAppOnTaskSprintChange
				notifyInAppOnTaskPhaseChange
				notifyInAppOnTaskStatusColumnChange
				notifyInAppOnTaskProjectChange
				notifyInAppOnTaskSubtaskChange
				notifyInAppOnTaskSubtaskEstimateChange
				notifyInAppOnTaskCommentChange
				notifyInAppOnTaskFileChange
				notifyInAppOnTaskBugChange
				notifyInAppOnTaskBlockedChange
				notifyInAppOnTaskRepeatingChange
				notifyInAppOnTimeOffManager
				notifyInAppOnTimeOffOwner
				disableInAppNotifications
				projectDeadlineInAppNotificationPeriod
				taskDeadlineInAppNotificationPeriod
				lastSeenInAppNotification
				sendNewsletter
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				startDate
				endDate
				timerStartDate
				timerEndDate
				timerTask {
					id
				}
				setToRunningNotificationLastDismissalDate
				gitlabUser {
					integrationUserId
				}
				gitlabServerUser {
					integrationUserId
				}
				githubUser {
					integrationUserId
				}
				unit4UserObject
				jiraCloudId
				jiraServerId
				sageIntacctId
				sageIntacctName
				sageIntacctEmail
				sageIntacctLocationId
				adoUserId
				hiBobId
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {
		email: input.email,
		jobTitle: input.jobTitle,
		firstName: input.firstName,
		lastName: input.lastName,
		phone: input.phone,
		initials: input.initials,
		notificationFrequency: input.notificationFrequency,
		notifyOnPersonJoin: input.notifyOnPersonJoin,
		notifyOnProjectStatusChange: input.notifyOnProjectStatusChange,
		notifyOnAssignedProject: input.notifyOnAssignedProject,
		notifyOnProjectDeadline: input.notifyOnProjectDeadline,
		notifyOnAssignedTask: input.notifyOnAssignedTask,
		notifyOnAssignedTaskUpdate: input.notifyOnAssignedTaskUpdate,
		notifyOnTaskDeadline: input.notifyOnTaskDeadline,
		notifyOnMention: input.notifyOnMention,
		notifyOnTaskDescriptionChange: input.notifyOnTaskDescriptionChange,
		notifyOnTaskTitleChange: input.notifyOnTaskTitleChange,
		notifyOnTaskEstimateChange: input.notifyOnTaskEstimateChange,
		notifyOnTaskStartDateChange: input.notifyOnTaskStartDateChange,
		notifyOnTaskEndDateChange: input.notifyOnTaskEndDateChange,
		notifyOnTaskSprintChange: input.notifyOnTaskSprintChange,
		notifyOnTaskPhaseChange: input.notifyOnTaskPhaseChange,
		notifyOnTaskStatusColumnChange: input.notifyOnTaskStatusColumnChange,
		notifyOnTaskProjectChange: input.notifyOnTaskProjectChange,
		notifyOnTaskSubtaskChange: input.notifyOnTaskSubtaskChange,
		notifyOnTaskSubtaskEstimateChange: input.notifyOnTaskSubtaskEstimateChange,
		notifyOnTaskCommentChange: input.notifyOnTaskCommentChange,
		notifyOnTaskFileChange: input.notifyOnTaskFileChange,
		notifyOnTaskBugChange: input.notifyOnTaskBugChange,
		notifyOnTaskBlockedChange: input.notifyOnTaskBlockedChange,
		notifyOnTaskRepeatingChange: input.notifyOnTaskRepeatingChange,
		notifyOnTimeOffManager: input.notifyOnTimeOffManager,
		notifyOnTimeOffOwner: input.notifyOnTimeOffOwner,
		notifyOnInvoiceDueDate: input.notifyOnInvoiceDueDate,
		notifyOnInvoiceOverdue: input.notifyOnInvoiceOverdue,
		notifyOnInvoiceDaysOverdue: input.notifyOnInvoiceDaysOverdue,
		notifyOnInvoiceCreatedOrDeleted: input.notifyOnInvoiceCreatedOrDeleted,
		notifyOnInvoiceStatusChange: input.notifyOnInvoiceStatusChange,
		notifyOnInvoiceDateReached: input.notifyOnInvoiceDateReached,
		notifyOnInvoicePayment: input.notifyOnInvoicePayment,
		notifyOnInvoiceDateChanged: input.notifyOnInvoiceDateChanged,
		notifyOnInvoiceDueDateChanged: input.notifyOnInvoiceDueDateChanged,
		invoiceNotificationDaysOverdue: input.invoiceNotificationDaysOverdue,
		disableNotifications: input.disableNotifications,
		notifyInAppOnProjectStatusChange: input.notifyInAppOnProjectStatusChange,
		notifyInAppOnAssignedProject: input.notifyInAppOnAssignedProject,
		notifyInAppOnPersonJoin: input.notifyInAppOnPersonJoin,
		notifyInAppOnProjectDeadline: input.notifyInAppOnProjectDeadline,
		notifyInAppOnAssignedTask: input.notifyInAppOnAssignedTask,
		notifyInAppOnAssignedTaskUpdate: input.notifyInAppOnAssignedTaskUpdate,
		notifyInAppOnTaskDeadline: input.notifyInAppOnTaskDeadline,
		notifyInAppOnMention: input.notifyInAppOnMention,
		notifyInAppOnTaskDescriptionChange: input.notifyInAppOnTaskDescriptionChange,
		notifyInAppOnTaskTitleChange: input.notifyInAppOnTaskTitleChange,
		notifyInAppOnTaskEstimateChange: input.notifyInAppOnTaskEstimateChange,
		notifyInAppOnTaskStartDateChange: input.notifyInAppOnTaskStartDateChange,
		notifyInAppOnTaskEndDateChange: input.notifyInAppOnTaskEndDateChange,
		notifyInAppOnTaskSprintChange: input.notifyInAppOnTaskSprintChange,
		notifyInAppOnTaskPhaseChange: input.notifyInAppOnTaskPhaseChange,
		notifyInAppOnTaskStatusColumnChange: input.notifyInAppOnTaskStatusColumnChange,
		notifyInAppOnTaskProjectChange: input.notifyInAppOnTaskProjectChange,
		notifyInAppOnTaskSubtaskChange: input.notifyInAppOnTaskSubtaskChange,
		notifyInAppOnTaskSubtaskEstimateChange: input.notifyInAppOnTaskSubtaskEstimateChange,
		notifyInAppOnTaskCommentChange: input.notifyInAppOnTaskCommentChange,
		notifyInAppOnTaskFileChange: input.notifyInAppOnTaskFileChange,
		notifyInAppOnTaskBugChange: input.notifyInAppOnTaskBugChange,
		notifyInAppOnTaskBlockedChange: input.notifyInAppOnTaskBlockedChange,
		notifyInAppOnTaskRepeatingChange: input.notifyInAppOnTaskRepeatingChange,
		notifyInAppOnTimeOffManager: input.notifyInAppOnTimeOffManager,
		notifyInAppOnTimeOffOwner: input.notifyInAppOnTimeOffOwner,
		notifyInAppOnInvoiceDueDate: input.notifyInAppOnInvoiceDueDate,
		notifyInAppOnInvoiceOverdue: input.notifyInAppOnInvoiceOverdue,
		notifyInAppOnInvoiceDaysOverdue: input.notifyInAppOnInvoiceDaysOverdue,
		notifyInAppOnInvoiceCreatedOrDeleted: input.notifyInAppOnInvoiceCreatedOrDeleted,
		notifyInAppOnInvoiceStatusChange: input.notifyInAppOnInvoiceStatusChange,
		notifyInAppOnInvoiceDateReached: input.notifyInAppOnInvoiceDateReached,
		notifyInAppOnInvoicePayment: input.notifyInAppOnInvoicePayment,
		notifyInAppOnInvoiceDateChanged: input.notifyInAppOnInvoiceDateChanged,
		notifyInAppOnInvoiceDueDateChanged: input.notifyInAppOnInvoiceDueDateChanged,
		projectDeadlineInAppNotificationPeriod: input.projectDeadlineInAppNotificationPeriod,
		taskDeadlineInAppNotificationPeriod: input.taskDeadlineInAppNotificationPeriod,
		disableInAppNotifications: input.disableInAppNotifications,
		lastSeenInAppNotification: input.lastSeenInAppNotification,
		projectDeadlineNotificationPeriod: input.projectDeadlineNotificationPeriod,
		taskDeadlineNotificationPeriod: input.taskDeadlineNotificationPeriod,
		sendNewsletter: input.sendNewsletter,
		language: input.language,
		jiraCloudId: input.jiraCloudId,
		jiraServerId: input.jiraServerId,
		startDate: input.startDate,
		endDate: input.endDate,
		monday: input.monday,
		tuesday: input.tuesday,
		wednesday: input.wednesday,
		thursday: input.thursday,
		friday: input.friday,
		saturday: input.saturday,
		sunday: input.sunday,
		timerStartDate: input.timerStartDate,
		timerEndDate: input.timerEndDate,
		setToRunningNotificationLastDismissalDate: input.setToRunningNotificationLastDismissalDate,
		sageIntacctId: input.sageIntacctId,
		sageIntacctName: input.sageIntacctName,
		sageIntacctEmail: input.sageIntacctEmail,
		sageIntacctLocationId: input.sageIntacctLocationId,
		hiBobId: input.hiBobId,
		adoUserId: input.adoUserId,

		// These will still throw warnings due to a bug in Relay 9.1. It is fixed with version 10.1 with this commit: https://github.com/facebook/relay/commit/6a1586d5b70519d58423493c32192365d0072fa3
		timerTask: input.timerTaskId ? {id: input.timerTaskId} : undefined,
		role: input.roleId
			? {
					id: input.roleId,
					name: undefined,
			  }
			: undefined,
		department: input.departmentId
			? {
					id: input.departmentId,
					name: undefined,
			  }
			: undefined,
		holidayCalendar: input.holidayCalendarId ? {id: input.holidayCalendarId} : undefined,
		cost: undefined,
		profiles: undefined,
		invited: undefined,
		profilePictureId: undefined,
		personLabels: undefined,
		unseenNotifications: undefined,
		unit4UserObject: undefined,
		githubUser: undefined,
		gitlabUser: undefined,
		gitlabServerUser: undefined,
	};

	// Required fields - added this way Relay will throw a warning if it is not present
	if (input.id) {
		obj.id = input.id;
	}

	return {
		updateViewer: {
			errors: undefined,
			viewer: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Person Updated', {_Changed: changes});

	const variables = {...input};
	// ID is needed for mutations's optimistic response, but should not be included in the variables
	const variablesNoID = omit(variables, ['id', 'viewer']);

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...variablesNoID}},
			uploadables,
			configs: getConfigs(),
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit, mutationId: 'updateViewerMutation'};
