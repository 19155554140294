/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdatePhaseBaselineExpensesInput = {|
  projectId?: ?string,
  phaseBaselineExpenses?: ?$ReadOnlyArray<?BulkPhaseBaselineExpensesInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type BulkPhaseBaselineExpensesInput = {|
  id?: ?string,
  projectId?: ?string,
  expenseCategoryId?: ?string,
  phaseId?: ?string,
  deliverableId?: ?string,
  expenseCost?: ?number,
  expenseMarkup?: ?number,
  expenseRevenue?: ?number,
  expenseName?: ?string,
|};
export type bulkUpdatePhaseBaselineExpensesMutationVariables = {|
  input: BulkUpdatePhaseBaselineExpensesInput
|};
export type bulkUpdatePhaseBaselineExpensesMutationResponse = {|
  +bulkUpdatePhaseBaselineExpenses: ?{|
    +project: ?{|
      +phaseBaselineExpenses: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +expenseName: ?string,
            +expenseCategory: ?{|
              +name: ?string,
              +id: string,
            |},
            +expenseRevenue: ?number,
            +expenseCost: ?number,
            +expenseMarkup: ?number,
            +expenseProfit: ?number,
            +globalPhaseId: string,
            +deliverableId: ?number,
          |}
        |}>
      |}
    |}
  |}
|};
export type bulkUpdatePhaseBaselineExpensesMutation = {|
  variables: bulkUpdatePhaseBaselineExpensesMutationVariables,
  response: bulkUpdatePhaseBaselineExpensesMutationResponse,
|};
*/


/*
mutation bulkUpdatePhaseBaselineExpensesMutation(
  $input: BulkUpdatePhaseBaselineExpensesInput!
) {
  bulkUpdatePhaseBaselineExpenses(input: $input) {
    project {
      phaseBaselineExpenses(first: 1000000) {
        edges {
          node {
            id
            expenseName
            expenseCategory {
              name
              id
            }
            expenseRevenue
            expenseCost
            expenseMarkup
            expenseProfit
            globalPhaseId
            deliverableId
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkUpdatePhaseBaselineExpensesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PhaseBaselineExpenseTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhaseBaselineExpenseType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expenseName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseCategory",
            "kind": "LinkedField",
            "name": "expenseCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expenseRevenue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expenseCost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expenseMarkup",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expenseProfit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "globalPhaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliverableId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkUpdatePhaseBaselineExpensesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePhaseBaselineExpensesPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePhaseBaselineExpenses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": "phaseBaselineExpenses",
                "args": null,
                "concreteType": "PhaseBaselineExpenseTypeConnection",
                "kind": "LinkedField",
                "name": "__Project_phaseBaselineExpenses_connection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUpdatePhaseBaselineExpensesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePhaseBaselineExpensesPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePhaseBaselineExpenses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "PhaseBaselineExpenseTypeConnection",
                "kind": "LinkedField",
                "name": "phaseBaselineExpenses",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "phaseBaselineExpenses(first:1000000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Project_phaseBaselineExpenses",
                "kind": "LinkedHandle",
                "name": "phaseBaselineExpenses"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "bulkUpdatePhaseBaselineExpenses",
            "project",
            "phaseBaselineExpenses"
          ]
        }
      ]
    },
    "name": "bulkUpdatePhaseBaselineExpensesMutation",
    "operationKind": "mutation",
    "text": "mutation bulkUpdatePhaseBaselineExpensesMutation(\n  $input: BulkUpdatePhaseBaselineExpensesInput!\n) {\n  bulkUpdatePhaseBaselineExpenses(input: $input) {\n    project {\n      phaseBaselineExpenses(first: 1000000) {\n        edges {\n          node {\n            id\n            expenseName\n            expenseCategory {\n              name\n              id\n            }\n            expenseRevenue\n            expenseCost\n            expenseMarkup\n            expenseProfit\n            globalPhaseId\n            deliverableId\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82f2d8b988dc1067390d62b87f157939';

module.exports = node;
