/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdatePhaseDeliverablesInput = {|
  projectId?: ?string,
  phaseDeliverables?: ?$ReadOnlyArray<?BulkUpdatePhaseDeliverableInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type BulkUpdatePhaseDeliverableInput = {|
  id?: ?string,
  phaseId?: ?string,
  deliverableId?: ?string,
  projectId?: ?string,
|};
export type bulkUpdatePhaseDeliverablesMutationVariables = {|
  input: BulkUpdatePhaseDeliverablesInput
|};
export type bulkUpdatePhaseDeliverablesMutationResponse = {|
  +bulkUpdatePhaseDeliverables: ?{|
    +phaseDeliverables: ?$ReadOnlyArray<?{|
      +id: string
    |}>
  |}
|};
export type bulkUpdatePhaseDeliverablesMutation = {|
  variables: bulkUpdatePhaseDeliverablesMutationVariables,
  response: bulkUpdatePhaseDeliverablesMutationResponse,
|};
*/


/*
mutation bulkUpdatePhaseDeliverablesMutation(
  $input: BulkUpdatePhaseDeliverablesInput!
) {
  bulkUpdatePhaseDeliverables(input: $input) {
    phaseDeliverables {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkUpdatePhaseDeliverablesInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkUpdatePhaseDeliverablesPayload",
    "kind": "LinkedField",
    "name": "bulkUpdatePhaseDeliverables",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhaseDeliverable",
        "kind": "LinkedField",
        "name": "phaseDeliverables",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkUpdatePhaseDeliverablesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUpdatePhaseDeliverablesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bulkUpdatePhaseDeliverablesMutation",
    "operationKind": "mutation",
    "text": "mutation bulkUpdatePhaseDeliverablesMutation(\n  $input: BulkUpdatePhaseDeliverablesInput!\n) {\n  bulkUpdatePhaseDeliverables(input: $input) {\n    phaseDeliverables {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c74f9205c2b34983394a046c76c33ec1';

module.exports = node;
