import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {
	getStoreNodeById,
	mutateAllEdgesInConnection,
	removeEdgesFromConnectionByDeletedIds,
} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation deleteProjectPersonMutation($input: DeleteProjectPersonInput!) {
		deleteProjectPerson(input: $input) {
			deletedProjectPersonIds
			project {
				id
				projectPersons(first: 10000, excludeClientUsers: true) {
					edges {
						node {
							id
							isContactPerson
							role {
								id
								name
							}
							person {
								id
								firstName
								lastName
								fullName
								initials
								permissions
								email
								profilePictureId
								profilePictureDefaultId
								active
								isViewer
								harvestUser
								role {
									id
									name
								}
								personLabels(first: 1000) {
									edges {
										node {
											id
											label {
												id
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
			projectGroup {
				id
				projects(first: 100000) {
					edges {
						node {
							projectPersons(first: 10000, excludeClientUsers: true) {
								edges {
									node {
										id
										isContactPerson
										role {
											id
											name
										}
										person {
											id
											firstName
											lastName
											fullName
											initials
											permissions
											email
											profilePictureId
											profilePictureDefaultId
											active
											isViewer
											harvestUser
											role {
												id
												name
											}
											personLabels(first: 1000) {
												edges {
													node {
														id
														label {
															id
															name
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			projects {
				edges {
					node {
						id
						name
						client {
							id
						}
						projectPersons(first: 1000, includeInactive: true)
							@connection(key: "Project_projectPersons", filters: []) {
							edges {
								node {
									id
									person {
										id
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];
	if (input.projectId) {
		configs.push({
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_projectPersons',
				},
			],
			pathToConnection: ['project', 'projectPersons'],
			deletedIDFieldName: 'deletedProjectPersonIds',
		});
	}
	return configs;
}

function updater(store, input, payload) {
	// If the mutation is called to add a projectPerson to a projectGroup, add the projectPerson edge to all projects in that projectGroup
	if (input.projectGroupId) {
		const deletedProjectPersonIds = payload.getValue('deletedProjectPersonIds');

		const projectGroupNode = getStoreNodeById({store, id: input.projectGroupId});
		if (projectGroupNode) {
			mutateAllEdgesInConnection({
				parentNode: projectGroupNode,
				connectionName: 'ProjectGroup_projects',
				callback: projectNode =>
					removeEdgesFromConnectionByDeletedIds({
						parentNode: projectNode,
						connectionName: 'Project_projectPersons',
						deletedIds: deletedProjectPersonIds,
					}),
			});
		}
	}
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Project Person Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		updater: store => {
			const payload = store.getRootField('deleteProjectPerson');
			updater(store, input, payload);
		},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
