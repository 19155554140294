// @ts-check
import { useEffect, useRef } from "react";
import { useSingleton } from "../../../forecast-app/shared/hooks/useSingleton";
import { registerSuperProperty, SUPER_PROPERTY, trackEvent, unregisterSuperProperty } from "../TrackingV2";
/**
 * Hook that handles tracking along with super property registration and unregistration for Page Viewed events.
 * This function uses the useSingleton pattern to allow executing the tracking function before the component and all its children are rendered, but still only executing it once.
 * This allows us to register super properties and have them included in tracking calls made by a component's children on mount.
 * @param {string} pageName name of the page being tracking
 * @param {Object} [pageProperties={}] passed properties will be attached to the tracking event, but will not be included in the AMPLITUDE_PAGE_INFO super property
 * @param {Object} [pageSuperProperties={}] passed properties will be set as part of the AMPLITUDE_PAGE_INFO super property and will be attached to all future events until page is unmounted
 * @param {Array} [deps=[]] array of items that when changed will cause the callback to trigger again, works similar to the deps array of native React hooks.
 * Generally, you should not include something in the deps that you are not including in the Super Property.
 */
export const useTrackPage = (pageName, pageProperties = {}, pageSuperProperties = {}, deps = []) => {
    const checksumRef = useRef("");
    useSingleton(() => {
        const superProperties = Object.assign({ pageName: pageName }, pageSuperProperties);
        const properties = Object.assign(Object.assign({}, pageProperties), superProperties);
        checksumRef.current = registerSuperProperty(SUPER_PROPERTY.AMPLITUDE_PAGE_INFO, superProperties);
        trackEvent(pageName, "Page Viewed", properties);
    }, deps);
    useEffect(() => {
        return () => {
            unregisterSuperProperty(SUPER_PROPERTY.AMPLITUDE_PAGE_INFO, checksumRef.current);
        };
    }, deps);
};
