/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateClientInput = {|
  id: string,
  name?: ?string,
  street?: ?string,
  zip?: ?string,
  city?: ?string,
  vat?: ?string,
  country?: ?string,
  notes?: ?string,
  logoId?: ?string,
  quickbooksCustomerId?: ?string,
  quickbooksCustomerRealmId?: ?string,
  economicCustomerId?: ?number,
  xeroContactId?: ?string,
  salesforceAccountId?: ?string,
  hubspotCompanyId?: ?string,
  pipedriveOrganizationId?: ?string,
  sageIntacctCustomerId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateClientMutationVariables = {|
  input: UpdateClientInput
|};
export type updateClientMutationResponse = {|
  +updateClient: ?{|
    +client: ?{|
      +id: string,
      +name: ?string,
      +street: ?string,
      +zip: ?string,
      +city: ?string,
      +vat: ?string,
      +country: ?string,
      +notes: ?string,
      +logoId: ?string,
      +logoDefaultId: ?number,
      +quickbooksCustomerId: ?string,
      +economicCustomerId: ?string,
      +xeroContactId: ?string,
      +salesforceAccountId: ?string,
      +hubspotCompanyId: ?string,
      +pipedriveOrganizationId: ?string,
      +sageIntacctCustomerId: ?string,
    |}
  |}
|};
export type updateClientMutation = {|
  variables: updateClientMutationVariables,
  response: updateClientMutationResponse,
|};
*/


/*
mutation updateClientMutation(
  $input: UpdateClientInput!
) {
  updateClient(input: $input) {
    client {
      id
      name
      street
      zip
      city
      vat
      country
      notes
      logoId
      logoDefaultId
      quickbooksCustomerId
      economicCustomerId
      xeroContactId
      salesforceAccountId
      hubspotCompanyId
      pipedriveOrganizationId
      sageIntacctCustomerId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateClientInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateClientPayload",
    "kind": "LinkedField",
    "name": "updateClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Client",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zip",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoDefaultId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quickbooksCustomerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "economicCustomerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xeroContactId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salesforceAccountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hubspotCompanyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pipedriveOrganizationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sageIntacctCustomerId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateClientMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateClientMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateClientMutation",
    "operationKind": "mutation",
    "text": "mutation updateClientMutation(\n  $input: UpdateClientInput!\n) {\n  updateClient(input: $input) {\n    client {\n      id\n      name\n      street\n      zip\n      city\n      vat\n      country\n      notes\n      logoId\n      logoDefaultId\n      quickbooksCustomerId\n      economicCustomerId\n      xeroContactId\n      salesforceAccountId\n      hubspotCompanyId\n      pipedriveOrganizationId\n      sageIntacctCustomerId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69946406b7b2271a1229abd5b60b3147';

module.exports = node;
