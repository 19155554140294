import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation bulkUpdatePhaseBaselineRolesMutation($input: BulkUpdatePhaseBaselineRolesInput!) {
		bulkUpdatePhaseBaselineRoles(input: $input) {
			project {
				phaseBaselineRoles(first: 1000000) @connection(key: "Project_phaseBaselineRoles", filters: []) {
					edges {
						node {
							id
							globalPhaseId
							deliverableId
							role {
								name
								id
							}
							financialNumbers(convertToProjectCurrency: true) {
								baselineMinutes
								baselineTimeAndExpenses
								baselineRevenue
								baselineCost
								baselineProfit
								baselineRatePerHour
								baselineCostPerHour
							}
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
