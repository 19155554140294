import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation bulkUpdatePhaseBaselineExpensesMutation($input: BulkUpdatePhaseBaselineExpensesInput!) {
		bulkUpdatePhaseBaselineExpenses(input: $input) {
			project {
				phaseBaselineExpenses(first: 1000000) @connection(key: "Project_phaseBaselineExpenses", filters: []) {
					edges {
						node {
							id
							expenseName
							expenseCategory {
								name
								id
							}
							expenseRevenue
							expenseCost
							expenseMarkup
							expenseProfit
							globalPhaseId
							deliverableId
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
