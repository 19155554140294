/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdatePhaseBaselineRolesInput = {|
  projectId?: ?string,
  phaseBaselineRoles?: ?$ReadOnlyArray<?BulkPhaseBaselineRolesInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type BulkPhaseBaselineRolesInput = {|
  id?: ?string,
  projectId?: ?string,
  roleId?: ?string,
  phaseId?: ?string,
  deliverableId?: ?string,
  baselineMinutes?: ?number,
|};
export type bulkUpdatePhaseBaselineRolesMutationVariables = {|
  input: BulkUpdatePhaseBaselineRolesInput
|};
export type bulkUpdatePhaseBaselineRolesMutationResponse = {|
  +bulkUpdatePhaseBaselineRoles: ?{|
    +project: ?{|
      +phaseBaselineRoles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +globalPhaseId: string,
            +deliverableId: ?number,
            +role: ?{|
              +name: ?string,
              +id: string,
            |},
            +financialNumbers: ?{|
              +baselineMinutes: ?number,
              +baselineTimeAndExpenses: ?number,
              +baselineRevenue: ?number,
              +baselineCost: ?number,
              +baselineProfit: ?number,
              +baselineRatePerHour: ?number,
              +baselineCostPerHour: ?number,
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type bulkUpdatePhaseBaselineRolesMutation = {|
  variables: bulkUpdatePhaseBaselineRolesMutationVariables,
  response: bulkUpdatePhaseBaselineRolesMutationResponse,
|};
*/


/*
mutation bulkUpdatePhaseBaselineRolesMutation(
  $input: BulkUpdatePhaseBaselineRolesInput!
) {
  bulkUpdatePhaseBaselineRoles(input: $input) {
    project {
      phaseBaselineRoles(first: 1000000) {
        edges {
          node {
            id
            globalPhaseId
            deliverableId
            role {
              name
              id
            }
            financialNumbers(convertToProjectCurrency: true) {
              baselineMinutes
              baselineTimeAndExpenses
              baselineRevenue
              baselineCost
              baselineProfit
              baselineRatePerHour
              baselineCostPerHour
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkUpdatePhaseBaselineRolesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PhaseBaselineRoleTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhaseBaselineRoleType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "globalPhaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliverableId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "convertToProjectCurrency",
                "value": true
              }
            ],
            "concreteType": "FinancialNumbers",
            "kind": "LinkedField",
            "name": "financialNumbers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineMinutes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineTimeAndExpenses",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineRevenue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineCost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineProfit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineRatePerHour",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baselineCostPerHour",
                "storageKey": null
              }
            ],
            "storageKey": "financialNumbers(convertToProjectCurrency:true)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkUpdatePhaseBaselineRolesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePhaseBaselineRolesPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePhaseBaselineRoles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": "phaseBaselineRoles",
                "args": null,
                "concreteType": "PhaseBaselineRoleTypeConnection",
                "kind": "LinkedField",
                "name": "__Project_phaseBaselineRoles_connection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUpdatePhaseBaselineRolesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePhaseBaselineRolesPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePhaseBaselineRoles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "PhaseBaselineRoleTypeConnection",
                "kind": "LinkedField",
                "name": "phaseBaselineRoles",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "phaseBaselineRoles(first:1000000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Project_phaseBaselineRoles",
                "kind": "LinkedHandle",
                "name": "phaseBaselineRoles"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "bulkUpdatePhaseBaselineRoles",
            "project",
            "phaseBaselineRoles"
          ]
        }
      ]
    },
    "name": "bulkUpdatePhaseBaselineRolesMutation",
    "operationKind": "mutation",
    "text": "mutation bulkUpdatePhaseBaselineRolesMutation(\n  $input: BulkUpdatePhaseBaselineRolesInput!\n) {\n  bulkUpdatePhaseBaselineRoles(input: $input) {\n    project {\n      phaseBaselineRoles(first: 1000000) {\n        edges {\n          node {\n            id\n            globalPhaseId\n            deliverableId\n            role {\n              name\n              id\n            }\n            financialNumbers(convertToProjectCurrency: true) {\n              baselineMinutes\n              baselineTimeAndExpenses\n              baselineRevenue\n              baselineCost\n              baselineProfit\n              baselineRatePerHour\n              baselineCostPerHour\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47421128d9c5cab8bcf9e8424979a093';

module.exports = node;
