/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCustomFieldDefinitionInput = {|
  key?: ?string,
  displayName?: ?string,
  readOnly?: ?boolean,
  type?: ?string,
  entityType?: ?string,
  avaRef?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createCustomFieldDefinitionMutationVariables = {|
  input: CreateCustomFieldDefinitionInput
|};
export type createCustomFieldDefinitionMutationResponse = {|
  +createCustomFieldDefinition: ?{|
    +customFieldDefinition: ?{|
      +node: ?{|
        +id: string,
        +key: ?string,
        +displayName: ?string,
        +readOnly: ?boolean,
        +entityType: ?string,
        +avaRef: ?number,
      |}
    |}
  |}
|};
export type createCustomFieldDefinitionMutation = {|
  variables: createCustomFieldDefinitionMutationVariables,
  response: createCustomFieldDefinitionMutationResponse,
|};
*/


/*
mutation createCustomFieldDefinitionMutation(
  $input: CreateCustomFieldDefinitionInput!
) {
  createCustomFieldDefinition(input: $input) {
    customFieldDefinition {
      node {
        id
        key
        displayName
        readOnly
        entityType
        avaRef
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCustomFieldDefinitionInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCustomFieldDefinitionPayload",
    "kind": "LinkedField",
    "name": "createCustomFieldDefinition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomFieldDefinitionTypeEdge",
        "kind": "LinkedField",
        "name": "customFieldDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomFieldDefinition",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avaRef",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCustomFieldDefinitionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCustomFieldDefinitionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createCustomFieldDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation createCustomFieldDefinitionMutation(\n  $input: CreateCustomFieldDefinitionInput!\n) {\n  createCustomFieldDefinition(input: $input) {\n    customFieldDefinition {\n      node {\n        id\n        key\n        displayName\n        readOnly\n        entityType\n        avaRef\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '55cba951bf6286169a8266e17014e554';

module.exports = node;
