import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updatePersonMutationModernMutation($input: UpdatePersonInput!) {
		updatePerson(input: $input) {
			errors
			person {
				email
				jobTitle
				department {
					id
				}
				firstName
				lastName
				initials
				seat
				profiles(first: 10000) {
					edges {
						node {
							id
							name
							seat
						}
					}
				}
				permissions
				cost
				phone
				language
				active
				invited
				profilePictureId
				excludeFromCompanyLockedPeriod
				startDate
				endDate
				role {
					id
					name
				}
				holidayCalendar {
					id
				}
				personLabels(first: 10000) {
					edges {
						node {
							label {
								id
							}
						}
					}
				}
				gitlabUser {
					integrationUserId
				}
				gitlabServerUser {
					integrationUserId
				}
				githubUser {
					integrationUserId
				}
				unit4UserObject
				jiraCloudId
				jiraServerId
				hasSSOExemption
				hiBobId
				sageIntacctId
				sageIntacctName
				sageIntacctEmail
				sageIntacctLocationId
				adoUserId
			}
			viewer {
				permissions
				profiles(first: 10000) {
					edges {
						node {
							id
							name
							permissions
						}
					}
				}
				company {
					userSeats
					virtualSeats
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.active != null) {
		obj.active = input.active;
	}
	if (input.email) {
		obj.email = input.email;
	}
	if (input.jobTitle) {
		obj.jobTitle = input.jobTitle;
	}
	if (input.departmentId) {
		obj.department = {id: input.departmentId};
	}
	if (input.firstName) {
		obj.firstName = input.firstName;
	}
	if (input.lastName) {
		obj.lastName = input.lastName;
	}
	if (input.initials) {
		obj.initials = input.initials;
	}
	if (input.profileIds) {
		obj.profiles = {
			edges: input.profileIds.map(p => ({node: {id: p}})),
		};
	}
	if (input.roleId) {
		obj.roleId = input.roleId;
	}
	if (input.holidayCalendarId) {
		obj.holidayCalendar = {id: input.holidayCalendarId};
	}
	if (input.invited) {
		obj.invited = input.invited;
	}
	if (input.language === null) {
		obj.language = null;
	} else if (input.language !== undefined) {
		obj.language = input.language;
	}
	if (input.phone) {
		obj.phone = input.phone;
	}
	if (input.unit4PersonId) {
		obj.unit4UserObject = input.unit4PersonId;
	}

	if (input.jiraCloudId) {
		obj.jiraCloudId = input.jiraCloudId;
	}

	if (input.jiraServerId) {
		obj.jiraServerId = input.jiraServerId;
	}
	if (input.sageIntacctId) {
		obj.sageIntacctId = input.sageIntacctId;
	}
	if (input.sageIntacctName) {
		obj.sageIntacctName = input.sageIntacctName;
	}
	if (input.sageIntacctEmail) {
		obj.sageIntacctEmail = input.sageIntacctEmail;
	}
	if (input.sageIntacctLocationId) {
		obj.sageIntacctLocationId = input.sageIntacctLocationId;
	}
	if (input.startDate !== undefined) {
		obj.startDate = input.startDate;
	}
	if (input.endDate !== undefined) {
		obj.endDate = input.endDate;
	}
	if (input.hasSSOExemption !== undefined) {
		obj.hasSSOExemption = input.hasSSOExemption;
	}
	if (input.hiBobId) {
		obj.hiBobId = input.hiBobId;
	}

	return {
		updatePerson: {
			person: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Person Updated', {_Changed: changes});

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
			uploadables,
			configs: getConfigs(),
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
